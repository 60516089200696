import self from '../img/self.png';
import mock1 from '../img/mock1.png';
import mock2 from '../img/mock2.png';
import mock3 from '../img/mock3.png';
import mock4 from '../img/mock4.png';
import mock5 from '../img/mock5.png';

export let colors = ['rgb(0,255,164)', 'rgb(166,104,255)'];

const PersonalInfo = {
  firstName: 'Dananjaya',
  lastName: 'Ariyasena',
  initials: 'DK',
  position: 'a Backend Developer',
  currentCompany: 'On Cloud Service GmbH',
  cvName: 'N_D_K_Ariyasena.pdf',
  cvLocation: '/files/N_D_K_Ariyasena.pdf',
};

const MiniBio = [
  {
    emoji: '💼',
    text: `Backend Engineer at ${PersonalInfo.currentCompany}`,
  },
  {
    emoji: '🌎',
    text: 'Based in Germany 🇩🇪',
  },
  {
    emoji: '☕',
    text: 'Fueled by food & games',
  },
  {
    emoji: '📧',
    text: 'ndkariyasena@gmail.com',
  },
  {
    emoji: '📞',
    text: '+49-155-10397634',
  },
];

const Socials = [
  {
    link: 'https://www.linkedin.com/in/dananjayakuma/',
    icon: 'fa fa-linkedin',
    label: 'linkedin',
  },
  {
    link: 'https://github.com/ndkariyasena',
    icon: 'fa fa-github',
    label: 'github',
  },
  /* {
          link: "https://facebook.com",
          icon: 'fa fa-facebook',
          label: 'facebook'
      },
      {
          link: "https://instagram.com",
          icon: 'fa fa-instagram',
          label: 'instagram'
      },
      {
          link: "https://twitter.com",
          icon: "fa fa-twitter",
          label: 'twitter'
      } */
];

let BioDescription = `Hello! I'm ${PersonalInfo.firstName}. I'm ${PersonalInfo.position} at ${PersonalInfo.currentCompany}.`;
BioDescription += ` I specialize in building scalable and robust applications as an experienced backend developer with over ${
  new Date().getFullYear() - 2015
} years of industry experience.`;

export const info = {
  ...PersonalInfo,
  selfPortrait: self,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: MiniBio,
  socials: Socials,
  bio: BioDescription,
  skills: {
    proficientWith: ['NodeJs', 'NestJs', 'Typescript', 'git', 'Docker', 'Javascript', 'SQL & NoSQL', 'Microservers', 'REST API'],
    exposedTo: ['ReactJs', 'AWS', 'GraphQL', 'PHP'],
    nextGoals: ['AWS Certificate', 'Python'],
  },
  hobbies: [
    {
      label: 'Video Games',
      emoji: '🎮',
    },
    {
      label: 'Spicy Foods',
      emoji: '🌶',
    },
    {
      label: 'Movies',
      emoji: '🎥',
    },
    {
      label: 'Travel',
      emoji: '🧭',
    },
  ],
  portfolio_active: false,
  portfolio: [
    {
      title: 'Project 1',
      live: 'https://paytonpierce.dev', //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: 'https://github.com/paytonjewell', // this should be a link to the **repository** of the project, where the code is hosted.
      image: mock1,
    },
    {
      title: 'Project 2',
      live: 'https://paytonpierce.dev',
      source: 'https://github.com/paytonjewell',
      image: mock2,
    },
    {
      title: 'Project 3',
      live: 'https://paytonpierce.dev',
      source: 'https://github.com/paytonjewell',
      image: mock3,
    },
    {
      title: 'Project 4',
      live: 'https://paytonpierce.dev',
      source: 'https://github.com/paytonjewell',
      image: mock4,
    },
    {
      title: 'Project 5',
      live: 'https://paytonpierce.dev',
      source: 'https://github.com/paytonjewell',
      image: mock5,
    },
  ],
};
