import React from 'react';
import { isMobile } from 'react-device-detect';
import Style from './About.module.scss';
import Terminal from './Terminal';
import { Box } from '@mui/material';
import { info } from '../../info/Info';
import cvFile from '../../files/N_D_K_Ariyasena.pdf';

export default function About() {
  const firstName = info.firstName.toLowerCase();

  const proficientSkillLength =
    info.skills.proficientWith.length % 2 === 0 ? info.skills.proficientWith.length : info.skills.proficientWith.length + 1;
  const proficientSkillNextLine = proficientSkillLength / 2;

  const exposedToSkillLength = info.skills.exposedTo.length % 2 === 0 ? info.skills.exposedTo.length : info.skills.exposedTo.length + 1;
  const exposedToSkillNextLine = exposedToSkillLength / 2;

  const nextGoalsLength = info.skills.nextGoals.length % 2 === 0 ? info.skills.nextGoals.length : info.skills.nextGoals.length + 1;
  const nextGoalsNextLine = nextGoalsLength / 2;

  function aboutMeText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}_{info.lastName.toLowerCase()} $
          </span>{' '}
          cat about_{firstName}{' '}
        </p>
        <p style={{ lineHeight: 1.3 }}>
          <span style={{ color: info.baseColor }}>
            about_{firstName} <span className={Style.green}>(main)</span> ${' '}
          </span>
          {info.bio}
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            Resume <span className={Style.green}>(main)</span> ${' '}
          </span>
          <a href={cvFile} download>
            {info.cvName}
          </a>
        </p>
      </>
    );
  }

  function skillsText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}_{info.lastName.toLowerCase()} $
          </span>{' '}
          cd skills/tools
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            skills/tools <span className={Style.green}>(main)</span> $
          </span>{' '}
          ls
        </p>
        <p style={{ color: info.baseColor }}> Proficient With</p>
        <ul className={Style.skills}>
          {info.skills.proficientWith.map((proficiency, index) => (
            <li
              key={index}
              style={{
                paddingTop: !isMobile && proficientSkillNextLine === index ? '1rem' : '',
              }}
            >
              {proficiency}
            </li>
          ))}
        </ul>
        <p style={{ color: info.baseColor }}> Exposed To</p>
        <ul className={Style.skills}>
          {info.skills.exposedTo.map((skill, index) => (
            <li
              key={index}
              style={{
                paddingTop: !isMobile && exposedToSkillNextLine === index ? '1rem' : '',
              }}
            >
              {skill}
            </li>
          ))}
        </ul>
        <p style={{ color: info.baseColor }}> Goals</p>
        <ul className={Style.skills}>
          {info.skills.nextGoals.map((goal, index) => (
            <li
              key={index}
              style={{
                paddingTop: !isMobile && nextGoalsNextLine === index ? '1rem' : '',
              }}
            >
              {goal}
            </li>
          ))}
        </ul>
      </>
    );
  }

  function miscText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}_{info.lastName.toLowerCase()} $
          </span>{' '}
          cd hobbies/interests
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            hobbies/interests <span className={Style.green}>(main)</span> $
          </span>{' '}
          ls
        </p>
        <ul>
          {info.hobbies.map((hobby, index) => (
            <li key={index}>
              <Box component={'span'} mr={'1rem'}>
                {hobby.emoji}
              </Box>
              {hobby.label}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
      <Terminal text={aboutMeText()} />
      <Terminal text={skillsText()} />
      <Terminal text={miscText()} />
    </Box>
  );
}
